import { Inject, Injectable } from "@angular/core";
import { CanActivate, UrlTree, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { isSuccess, isInitial, isPending } from "@nll/datum/DatumEither";
import { notNil } from "@qqcw/qqsystem-util";
import { KeycloakService } from "keycloak-angular";
import { from, interval, Observable, of } from "rxjs";
import {
  catchError,
  delayWhen,
  filter,
  map,
  switchMap,
  take,
} from "rxjs/operators";
import { getParams } from "src/lib/util";
import {
  getProfile,
  selectInitialLoad,
  selectProfile,
  setInitialLoad,
} from "../ngrx/myqq";
import {
  setExistingMembership,
  setUIPromoCode,
  setSalesPipeline,
  selectKeycloakInitialized,
  setB2cSignUpDetails,
} from "../ngrx/ui";
import { WINDOW } from "../services/window.service";

const accountSetupUri = "/account-setup";
const profileTimeout = 500; // ms
@Injectable({
  providedIn: "root",
})
export class InitialRouteGuard implements CanActivate {
  constructor(
    readonly store$: Store<any>,
    readonly router: Router,
    readonly keycloak: KeycloakService,
    @Inject(WINDOW) readonly window: Window
  ) {}
  canActivate(): Observable<boolean | UrlTree> {
    const { params } = getParams(window?.location?.search);

    return this.store$.select(selectInitialLoad).pipe(
      take(1),
      switchMap((initial) => {
        this.store$.dispatch(setInitialLoad(null));
        return !initial
          ? of(true) // On subsequent route changes, immediately allow.
          : this.store$.select(selectKeycloakInitialized).pipe(
              filter((init) => init),
              switchMap(() =>
                from(
                  this.keycloak
                    .isLoggedIn()
                    .catch((_) => Promise.resolve(false))
                ).pipe(
                  switchMap((auth) => {
                    // Set promo code if it exists.
                    if (params?.promo) {
                      this.store$.dispatch(setUIPromoCode(params.promo));
                    }
                    // Set salespipeline flag and details if user is coming from sales pipeline if
                    // SKU and zip are present in query params.
                    if (params?.zip && params?.sku) {
                      this.store$.dispatch(
                        setSalesPipeline({
                          isSalesPipeline: true,
                          origination: params?.origination,
                          zipcode: params.zip,
                          subscriptionSKU: params.sku,
                        })
                      );
                    } else if (params?.hasMembership) {
                      this.store$.dispatch(
                        setExistingMembership({
                          hasMembership: true,
                        })
                      );
                    }

                    // Set b2c code if it exists.
                    if (!!params) {
                      let email = "";
                      try {
                        email = decodeURIComponent(params.email);
                      } catch (e) {
                        email = params.email;
                      }
                      this.store$.dispatch(
                        setB2cSignUpDetails({
                          benefitcode: params.benefitcode,
                          email: email,
                        })
                      );
                    }

                    // If unauthenticated, permit access without redirect
                    // The keycloak guard will prevent unauthed access and redirect to log in if need be.
                    if (!auth) {
                      return of(true);
                    }
                    // If authenticated, get the profile to check if account is set up
                    const profile$ = this.store$.select(selectProfile);
                    this.store$.dispatch(getProfile.pending({ track: true }));

                    return profile$.pipe(
                      filter((r) => !isInitial(r)),
                      delayWhen((r) =>
                        isPending(r) ? interval(profileTimeout) : interval(0)
                      ),
                      take(1),
                      map((r) => {
                        if (isSuccess(r)) {
                          const membership = r.value.right.membership;
                          if (!notNil(membership)) {
                            const searchParam = window?.location?.search;
                            if (
                              (params?.zip && params?.sku) ||
                              params?.hasMembership
                            ) {
                              // DO SALES PIPELINE NGRX STUFF HERE
                              return this.router.createUrlTree(
                                [accountSetupUri],
                                {
                                  queryParams: this.parseParams(searchParam),
                                }
                              );
                            }
                            return this.router.createUrlTree([""], {});
                          }
                        }
                        return true;
                      }),
                      catchError(() => {
                        return of(this.router.createUrlTree([""]));
                      })
                    );
                  })
                )
              )
            );
      })
    );
  }

  parseParams(params: string): { [key: string]: string } {
    const paramObject = {};

    params
      .split("?")[1]
      .split("&")
      .forEach((p) => {
        const [key, value] = p.split("=");
        paramObject[key] = value;
      });

    return paramObject;
  }
}
