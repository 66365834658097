// THIS FILE IS AUTOGENERATED

export interface Version {
  commit: string;
  version: string;
  app: string;
}

export const version = {
  app: "myqq",
  commit: "ee535bad90494a558c8040c2c84d74067cec25f7",
  version: "1.102.3",
};
